import { css } from '@emotion/css';
import { theme } from 'core/theme';

export const root = css`
  @media (min-width: ${theme.breakpoints.values.lg}px) {
    min-width: 15vw;
  }
`;

export const buttonText = css`
  margin-left: 1rem;
`;
