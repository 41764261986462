export const keys = {
  form: {
    subject: 'form.subject',
    name: 'form.name',
    email: 'form.email',
    company: 'form.company',
    phone: 'form.phone',
    message: 'form.message',
    button: 'form.button',
    acceptPrivacyPolicy: 'form.acceptPrivacyPolicy',
  },
  snackbar: {
    success: 'snackbar.success',
    error: 'snackbar.error',
  },
};
