export const keys = {
  terminals: {
    title: 'terminals.title',
    barcelona: 'terminals.barcelona',
    huelva: 'terminals.huelva',
  },
  company: {
    title: 'company.title',
    productAndServices: 'company.productAndServices',
    whoWeAre: 'company.whoWeAre',
    commitments: 'company.commitments',
    authorization: 'company.authorization',
    certifications: 'company.certifications',
  },
  policies: {
    title: 'policies.title',
    ethicalCode: 'policies.ethicalCode',
    csmas: 'policies.csmas',
    privacyAndDataProtection: 'policies.privacyAndDataProtection',
  },
  news: 'news',
  contact: 'contact',
  owner: 'owner',
  legalDisclaimer: 'legalDisclaimer',
  privacy: 'privacy',
  cookies: 'cookies',
};
