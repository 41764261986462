import { keys } from './keys';

export const en = {
  [keys.authorizationList.title]: 'Customs Authorisations',
  [keys.authorizationList.headerList.activity]: 'Activity',
  [keys.authorizationList.headerList.certification]: 'Certificate / Authorisation Nº',
  [keys.authorizationList.headerList.code]: 'Global Customs Guarantee: Guarantee Code/Guarantee Waiver Code',
  [keys.authorizationList.terminalList.barcelona]: 'Barcelona',
  [keys.authorizationList.terminalList.huelva]: 'Huelva',
  [keys.authorizationList.terminalList.general]: 'General',
};
