export const keys = {
  terminalList: {
    title: 'terminalList.title',
    surface: 'terminalList.surface',
    capacity: 'terminalList.capacity',
    tanks: 'terminalList.tanks',
    range: 'terminalList.range',
  },
  certificationList: {
    title: 'certificationList.title',
  },
  login: {
    title: 'login.title',
    form: {
      user: 'login.form.user',
      password: 'login.form.password',
    },
  },
  newList: {
    title: 'newList.title',
    button: {
      seeAll: 'newList.button.seeAll',
      share: 'newList.button.share',
    },
  },
  contact: {
    title: 'contact.title',
    subtitle: 'contact.subtitle',
    button: 'contact.button',
  },
};
