import { keys } from './keys';

export const es = {
  [keys.terminals.title]: 'Terminales',
  [keys.terminals.barcelona]: 'Barcelona',
  [keys.terminals.huelva]: 'Huelva',
  [keys.company.title]: 'Empresa',
  [keys.company.productAndServices]: 'Productos y servicios',
  [keys.company.whoWeAre]: 'Quienes somos',
  [keys.company.whatDoWeDo]: 'Qué hacemos',
  [keys.company.commitments]: 'Compromiso',
  [keys.company.authorization]: 'Datos fiscales y autorizaciones',
  [keys.company.certifications]: 'Certificaciones',
  [keys.policies.title]: 'Políticas',
  [keys.policies.ethicalCode]: 'Código Ético / Compliance',
  [keys.policies.csmas]: 'CSMAS',
  [keys.policies.privacyAndDataProtection]: 'Privacidad y Protección de Datos',
  [keys.news]: 'Noticias',
  [keys.login]: 'Acceso Clientes',
  [keys.contact]: 'Contacto',
};
