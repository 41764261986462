import React from 'react';
import { useTranslation } from 'react-i18next';
import { Validators } from '@lemoncode/fonk';
import { isTrue } from '@lemoncode/fonk-is-true-validator';
import { LanguageContext, namespaces } from 'core/i18n';
import { keys } from './translations';

export const useGlobalValidationMessages = () => {
  const { language } = React.useContext(LanguageContext);
  const { t } = useTranslation(namespaces.commonApp.validations);

  React.useEffect(() => {
    Validators.required.setErrorMessage(t(keys.required));
    Validators.email.setErrorMessage(t(keys.email));
    isTrue.setErrorMessage(t(keys.isTrue));
  }, [language]);
};
