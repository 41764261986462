export const languages = {
  es: 'es',
  en: 'en',
};

export type Language = 'es' | 'en';

export const namespaces = {
  core: 'core',
  commonApp: {
    appBar: 'commonApp.appBar',
    footer: 'commonApp.footer',
    new: 'commonApp.new',
    validations: 'commonApp.validations',
    cookesPopup: 'commonApp.cookesPopup',
  },
  pods: {
    home: 'pods.home',
    terminal: 'pods.terminal',
    productAndServices: 'pods.productAndServices',
    newList: 'pods.newList',
    privacy: 'pods.privacy',
    legalDisclaimer: 'pods.legalDisclaimer',
    cookies: 'pods.cookies',
    contact: 'pods.contact',
    authorization: 'pods.authorization',
  },
};
