exports.components = {
  "component---src-pages-aviso-legal-tsx": () => import("./../../../src/pages/aviso-legal.tsx" /* webpackChunkName: "component---src-pages-aviso-legal-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-empresa-certificaciones-tsx": () => import("./../../../src/pages/empresa/certificaciones.tsx" /* webpackChunkName: "component---src-pages-empresa-certificaciones-tsx" */),
  "component---src-pages-empresa-compromiso-tsx": () => import("./../../../src/pages/empresa/compromiso.tsx" /* webpackChunkName: "component---src-pages-empresa-compromiso-tsx" */),
  "component---src-pages-empresa-fiscal-y-autorizaciones-tsx": () => import("./../../../src/pages/empresa/fiscal-y-autorizaciones.tsx" /* webpackChunkName: "component---src-pages-empresa-fiscal-y-autorizaciones-tsx" */),
  "component---src-pages-empresa-productos-y-servicios-tsx": () => import("./../../../src/pages/empresa/productos-y-servicios.tsx" /* webpackChunkName: "component---src-pages-empresa-productos-y-servicios-tsx" */),
  "component---src-pages-empresa-que-hacemos-tsx": () => import("./../../../src/pages/empresa/que-hacemos.tsx" /* webpackChunkName: "component---src-pages-empresa-que-hacemos-tsx" */),
  "component---src-pages-empresa-quienes-somos-tsx": () => import("./../../../src/pages/empresa/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-empresa-quienes-somos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-noticias-tsx": () => import("./../../../src/pages/noticias.tsx" /* webpackChunkName: "component---src-pages-noticias-tsx" */),
  "component---src-pages-politicas-codigo-etico-tsx": () => import("./../../../src/pages/politicas/codigo-etico.tsx" /* webpackChunkName: "component---src-pages-politicas-codigo-etico-tsx" */),
  "component---src-pages-politicas-csmas-tsx": () => import("./../../../src/pages/politicas/csmas.tsx" /* webpackChunkName: "component---src-pages-politicas-csmas-tsx" */),
  "component---src-pages-politicas-privacidad-y-proteccion-de-datos-tsx": () => import("./../../../src/pages/politicas/privacidad-y-proteccion-de-datos.tsx" /* webpackChunkName: "component---src-pages-politicas-privacidad-y-proteccion-de-datos-tsx" */),
  "component---src-pages-ppa-information-tsx": () => import("./../../../src/pages/ppa-information.tsx" /* webpackChunkName: "component---src-pages-ppa-information-tsx" */),
  "component---src-pages-privacidad-tsx": () => import("./../../../src/pages/privacidad.tsx" /* webpackChunkName: "component---src-pages-privacidad-tsx" */),
  "component---src-pages-terminales-barcelona-tsx": () => import("./../../../src/pages/terminales/barcelona.tsx" /* webpackChunkName: "component---src-pages-terminales-barcelona-tsx" */),
  "component---src-pages-terminales-huelva-tsx": () => import("./../../../src/pages/terminales/huelva.tsx" /* webpackChunkName: "component---src-pages-terminales-huelva-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../../../src/templates/news.template.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */)
}

