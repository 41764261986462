export const keys = {
  featureList: {
    feature: {
      title: 'featureList.feature.title',
      surface: 'featureList.feature.surface',
      capacity: 'featureList.feature.capacity',
      tanks: 'featureList.feature.tanks',
      range: 'featureList.feature.range',
    },
    capacityStatus: {
      available: 'featureList.capacityStatus.available',
      notAvailable: 'featureList.capacityStatus.notAvailable',
    },
    productTitle: 'featureList.productTitle',
  },
};
