import { keys } from './keys';

export const es = {
  [keys.terminals.title]: 'Terminales',
  [keys.terminals.barcelona]: 'Barcelona',
  [keys.terminals.huelva]: 'Huelva',
  [keys.company.title]: 'Empresa',
  [keys.company.productAndServices]: 'Productos y servicios',
  [keys.company.whoWeAre]: 'Quienes somos',
  [keys.company.commitments]: 'Compromiso',
  [keys.company.authorization]: 'Datos fiscales y autorizaciones',
  [keys.company.certifications]: 'Certificaciones',
  [keys.policies.title]: 'Políticas',
  [keys.policies.ethicalCode]: 'Código Ético / Compliance',
  [keys.policies.csmas]: 'CSMAS',
  [keys.policies.privacyAndDataProtection]: 'Protección de Datos',
  [keys.news]: 'Noticias',
  [keys.contact]: 'Contacto',
  [keys.owner]: 'DECAL ESPAÑA S.A.U',
  [keys.legalDisclaimer]: 'Aviso Legal',
  [keys.privacy]: 'Privacidad',
  [keys.cookies]: 'Cookies',
};
