import { keys } from './keys';

export const en = {
  [keys.terminals.title]: 'Terminals',
  [keys.terminals.barcelona]: 'Barcelona',
  [keys.terminals.huelva]: 'Huelva',
  [keys.company.title]: 'Company',
  [keys.company.productAndServices]: 'Products and services',
  [keys.company.whoWeAre]: 'Who we are',
  [keys.company.whatDoWeDo]: 'What do we do',
  [keys.company.commitments]: 'Commitment',
  [keys.company.authorization]: 'Tax data and authorizations',
  [keys.company.certifications]: 'Certifications',
  [keys.policies.title]: 'Policies',
  [keys.policies.ethicalCode]: 'Ethical Code / Compliance',
  [keys.policies.csmas]: 'CSMAS',
  [keys.policies.privacyAndDataProtection]: 'Privacy and Data Protection',
  [keys.news]: 'News',
  [keys.login]: 'Customer Access',
  [keys.contact]: 'Contact',
};
