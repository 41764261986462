import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Typography, Drawer, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { namespaces } from 'core/i18n';
import { keys } from '../translations';
import { routes, externalRoutes } from 'core/router';
import { ExternalLinkComponent } from 'common/components/external-link';
import * as classes from './mobile-nav.styles';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const MobileNavComponent: React.FC<Props> = props => {
  const { open, onClose } = props;
  const { t } = useTranslation(namespaces.commonApp.appBar);

  const handleNavigate = (route: string) => {
    onClose();
    navigate(route);
  };

  return (
    <Drawer
      classes={{
        paper: classes.root,
      }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <List>
        <ListItem>
          <ListItemText>
            <Typography variant="subtitle1">{t(keys.terminals.title)}</Typography>
          </ListItemText>
        </ListItem>
        <ListItemButton onClick={() => handleNavigate(routes.terminals.barcelona)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.terminals.barcelona)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.terminals.huelva)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.terminals.huelva)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItem>
          <ListItemText>
            <Typography variant="subtitle1">{t(keys.company.title)}</Typography>
          </ListItemText>
        </ListItem>
        <ListItemButton onClick={() => handleNavigate(routes.company.productAndServices)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.company.productAndServices)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.company.whoWeAre)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.company.whoWeAre)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.company.whatDoWeDo)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.company.whatDoWeDo)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.company.commitments)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.company.commitments)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.company.authorization)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.company.authorization)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.company.certifications)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.company.certifications)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItem>
          <ListItemText>
            <Typography variant="subtitle1">{t(keys.policies.title)}</Typography>
          </ListItemText>
        </ListItem>
        <ListItemButton onClick={() => handleNavigate(routes.policies.ethicalCode)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.policies.ethicalCode)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.policies.csmas)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.policies.csmas)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.policies.privacyAndDataProtection)}>
          <ListItemText className={classes.buttonText}>
            <Typography variant="body1">{t(keys.policies.privacyAndDataProtection)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.news.list)}>
          <ListItemText>
            <Typography variant="subtitle1">{t(keys.news)}</Typography>
          </ListItemText>
        </ListItemButton>
        <ListItemButton>
          <ListItemText>
            <ExternalLinkComponent href={externalRoutes.privateSite}>
              <Typography variant="subtitle1">{t(keys.login)}</Typography>
            </ExternalLinkComponent>
          </ListItemText>
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigate(routes.contact)}>
          <ListItemText>
            <Typography variant="subtitle1">{t(keys.contact)}</Typography>
          </ListItemText>
        </ListItemButton>
      </List>
    </Drawer>
  );
};
