import React from 'react';
import { Language } from './i18n.constants';
import { LanguageContext } from './language.context';

interface Item {
  _language: Language;
}

export const useLanguageFilteredList = <T extends Item>(list: T[]) => {
  const { language } = React.useContext(LanguageContext);

  const filteredList = React.useMemo(() => list.filter(c => c._language === language), [language, list]);

  return [filteredList];
};
