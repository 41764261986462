import { keys } from './keys';

export const es = {
  [keys.authorizationList.title]: 'Autorizaciones aduaneras',
  [keys.authorizationList.headerList.activity]: 'Actividad',
  [keys.authorizationList.headerList.certification]: 'Nº de Certificado / Autorización',
  [keys.authorizationList.headerList.code]: 'Garantía Global Aduanera: Código Garantía/Código Dispensa Garantía',
  [keys.authorizationList.terminalList.barcelona]: 'Barcelona',
  [keys.authorizationList.terminalList.huelva]: 'Huelva',
  [keys.authorizationList.terminalList.general]: 'General',
};
