import { keys } from './keys';

export const es = {
  [keys.featureList.feature.title]: 'Características',
  [keys.featureList.feature.surface]: 'Superficie total',
  [keys.featureList.feature.capacity]: 'Capacidad total',
  [keys.featureList.feature.tanks]: 'Número de tanques',
  [keys.featureList.feature.range]: 'Rango capacidades',
  [keys.featureList.capacityStatus.available]: 'Capacidad Disponible',
  [keys.featureList.capacityStatus.notAvailable]: 'Capacidad No Disponible',
  [keys.featureList.productTitle]: 'Productos',
};
