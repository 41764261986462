import React from 'react';
import { ThemeProviderComponent } from 'core/theme';
import { LanguageProvider } from 'core/i18n';
import { SnackbarProvider, SnackbarComponent } from 'common/components/snackbar';

interface Props {
  children: React.ReactNode;
}

export const Providers: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProviderComponent>
      <LanguageProvider>
        <SnackbarProvider>
          {children}
          <SnackbarComponent />
        </SnackbarProvider>
      </LanguageProvider>
    </ThemeProviderComponent>
  );
};
