import { css } from '@emotion/css';
import { theme } from 'core/theme';

export const root = css`
  display: flex;
  flex-direction: row;
  color: ${theme.palette.text.primary};
  margin: 0;
  & > :nth-child(n) {
    padding: 1rem;
    cursor: pointer;
  }
`;

export const menuElevation = css`
  border-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.2), 0px 6px 5px 0px rgba(0, 0, 0, 0.14);
`;

export const title = css`
  &:hover {
    color: ${theme.palette.custom.text.selected};
    font-weight: 400;
  }
`;
