import { css } from '@emotion/css';
import { theme } from 'core/theme';

export const popover = css`
  pointer-events: none;
`;

export const paper = css`
  pointer-events: initial;
  border-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.2), 0px 6px 5px 0px rgba(0, 0, 0, 0.14);
`;

interface TitleProps {
  isOpen: boolean;
}

export const title = (props: TitleProps) => css`
  color: ${props.isOpen ? theme.palette.custom.text.selected : theme.palette.text.primary};
  font-weight: ${props.isOpen ? 400 : 300};
`;

export const menu = css`
  & > :nth-child(n) {
    background-color: transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      & > p {
        color: ${theme.palette.custom.text.selected};
        font-weight: 400;
      }
    }
  }
`;
