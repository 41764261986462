import React from 'react';
import { Link } from '@mui/material';

interface Props {
  href: string;
  children?: React.ReactNode;
  target?: string;
  className?: string;
}

export const ExternalLinkComponent: React.FC<Props> = props => {
  const { href, target, className, children } = props;
  const hasHref = React.useMemo(() => Boolean(href), [href]);

  return (
    <>
      {hasHref ? (
        <Link className={className} href={href} target={target} rel="noopener noreferrer">
          {children}
        </Link>
      ) : (
        <span className={className}>{children}</span>
      )}
    </>
  );
};

ExternalLinkComponent.defaultProps = {
  target: '_blank',
};
