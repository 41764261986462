import { css } from '@emotion/css';
import { darken } from '@mui/material/styles';
import { theme } from 'core/theme';

export const root = css`
  position: fixed;
  z-index: 1;
  bottom: 2rem;
  right: 2rem;
`;

export const fab = css`
  background-color: ${theme.palette.secondary.light};
  &:hover {
    background-color: ${darken(theme.palette.secondary.light, 0.25)};
  }
`;

export const icon = css`
  fill: ${theme.palette.common.white};
  width: 32px;
  height: 32px;
`;
