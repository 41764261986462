import React from 'react';
import { cx } from '@emotion/css';
import { navigate } from 'gatsby';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { routes, externalRoutes } from 'core/router';
import { theme } from 'core/theme';
import LogoIcon from 'core/assets/icons/logo.svg';
import MenuIcon from 'core/assets/icons/menu.svg';
import UserIcon from 'core/assets/icons/user-outline.svg';
import { useExpand, useMediaQuery } from 'common/hooks';
import { ScrollToTopComponent } from 'common/components/scroll-to-top';
import { ExternalLinkComponent } from 'common/components/external-link';
import { LanguageComponent, MobileNavComponent, NavComponent } from './components';
import * as classes from './app-bar.styles';

interface Props {
  backToTopId: string;
  className?: string;
}

export const AppBarComponent: React.FC<Props> = props => {
  const { backToTopId, className } = props;
  const { isExpanded, onToggleExpanded } = useExpand(false);
  const isMd = useMediaQuery(`(min-width: ${theme.breakpoints.values.md}px)`);

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={cx(classes.toolbar, className)} variant="dense">
          <LogoIcon onClick={() => navigate(routes.root)} className={classes.logo} />
          {isMd && <NavComponent />}
          <LanguageComponent />
          {isMd && (
            <ExternalLinkComponent href={externalRoutes.privateSite}>
              <IconButton>
                <UserIcon className={classes.icon} />
              </IconButton>
            </ExternalLinkComponent>
          )}
          {!isMd && (
            <>
              <IconButton onClick={onToggleExpanded}>
                <MenuIcon className={classes.icon} />
              </IconButton>
              <MobileNavComponent open={isExpanded} onClose={onToggleExpanded} />
            </>
          )}
        </Toolbar>
      </AppBar>
      {isMd && <ScrollToTopComponent id={backToTopId} />}
    </>
  );
};
