import React from 'react';
import { cx } from '@emotion/css';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Typography, Link } from '@mui/material';
import { Language, namespaces, useLanguageFilteredList } from 'core/i18n';
import { useTranslation } from 'react-i18next';
import { keys } from '../translations';
import { routes } from 'core/router';
import * as classes from './owner.styles';

const query = graphql`
  query {
    allContentIslandPpaInformation {
      nodes {
        title
        _language
      }
    }
  }
`;

interface Props {
  className?: string;
}

export const OwnerComponent: React.FC<Props> = props => {
  const { className } = props;
  const { t } = useTranslation(namespaces.commonApp.footer);

  const { allContentIslandPpaInformation } = useStaticQuery(query);
  const [ppaInformation] = useLanguageFilteredList<{
    title: string;
    _language: Language;
  }>(allContentIslandPpaInformation.nodes);

  return (
    <div className={classes.root}>
      <div className={cx(classes.content, className)}>
        <Typography className={classes.owner}>{t(keys.owner)}</Typography>
        <ul className={classes.linkContainer}>
          <li>
            <Link onClick={() => navigate(routes.legalDisclaimer)}>
              <Typography className={classes.linkText}>{t(keys.legalDisclaimer)}</Typography>
            </Link>
          </li>
          <li>
            <Link onClick={() => navigate(routes.privacy)}>
              <Typography className={classes.linkText}>{t(keys.privacy)}</Typography>
            </Link>
          </li>
          {/* NOTE: Keep in case we need it in the future. */}
          {/* <li>
            <Link onClick={() => navigate(routes.cookies)}>
              <Typography className={classes.linkText}>{t(keys.cookies)}</Typography>
            </Link>
          </li> */}
          <li>
            <Link onClick={() => navigate(routes.ppaInformation)}>
              <Typography className={classes.linkText}>{ppaInformation[0]?.title}</Typography>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
