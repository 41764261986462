import { keys } from './keys';

export const es = {
  [keys.form.subject]: 'Petición Contacto Web Pública decal',
  [keys.form.name]: 'Nombre',
  [keys.form.email]: 'Correo electrónico',
  [keys.form.company]: 'Compañía',
  [keys.form.phone]: 'Teléfono',
  [keys.form.message]: 'Mensaje',
  [keys.form.button]: 'Enviar',
  [keys.form.acceptPrivacyPolicy]: 'He leído y acepto la <0>Política de Privacidad</0>',
  [keys.snackbar.success]: '¡Gracias por contactar con nosotros!',
  [keys.snackbar.error]: 'Por favor, inténtelo de nuevo más tarde, algo salió mal :(',
};
