import React from 'react';

export const useExpand = (initialState: boolean) => {
  const [isExpanded, setIsExpanded] = React.useState(initialState);

  const onToggleExpanded = React.useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return {
    isExpanded,
    onToggleExpanded,
    setIsExpanded,
  };
};
