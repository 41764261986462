interface Routes {
  root: string;
  terminals: {
    barcelona: string;
    huelva: string;
  };
  company: {
    productAndServices: string;
    whoWeAre: string;
    whatDoWeDo: string;
    commitments: string;
    authorization: string;
    certifications: string;
  };
  policies: {
    ethicalCode: string;
    csmas: string;
    privacyAndDataProtection: string;
  };
  news: {
    list: string;
    getNew: (url: string) => string;
  };
  contact: string;
  legalDisclaimer: string;
  privacy: string;
  cookies: string;
  ppaInformation: string;
}

export const routes: Routes = {
  root: '/',
  terminals: {
    barcelona: '/terminales/barcelona',
    huelva: '/terminales/huelva',
  },
  company: {
    productAndServices: '/empresa/productos-y-servicios',
    whoWeAre: '/empresa/quienes-somos',
    whatDoWeDo: '/empresa/que-hacemos',
    commitments: '/empresa/compromiso',
    authorization: '/empresa/fiscal-y-autorizaciones',
    certifications: '/empresa/certificaciones',
  },
  policies: {
    ethicalCode: '/politicas/codigo-etico',
    csmas: '/politicas/csmas',
    privacyAndDataProtection: '/politicas/privacidad-y-proteccion-de-datos',
  },
  news: {
    list: '/noticias',
    getNew: (url: string) => `/noticias/${url}`,
  },
  contact: '/contacto',
  legalDisclaimer: '/aviso-legal',
  privacy: '/privacidad',
  cookies: '/cookies',
  ppaInformation: '/ppa-information',
};
