import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { createI18n } from './i18n';
import { languages } from './i18n.constants';

interface Context {
  language: string;
  onChangeLanguage: (language: string) => void;
}

export const LanguageContext = React.createContext<Context>(null);

interface Props {
  children: React.ReactNode;
}

export const LanguageProvider: React.FC<Props> = props => {
  const { children } = props;
  const [language, setLanguage] = React.useState(languages.es);

  const i18n = React.useMemo(() => createI18n(language), []);

  const handleChangeLanguage = newLanguage => {
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <LanguageContext.Provider
        value={{
          language,
          onChangeLanguage: handleChangeLanguage,
        }}
      >
        {children}
      </LanguageContext.Provider>
    </I18nextProvider>
  );
};
