import { keys } from './keys';

export const en = {
  [keys.terminalList.title]: 'Terminals',
  [keys.terminalList.surface]: 'Total area',
  [keys.terminalList.capacity]: 'Total capacity',
  [keys.terminalList.tanks]: 'Number of tanks',
  [keys.terminalList.range]: 'Capacity range',
  [keys.certificationList.title]: 'Certifications',
  [keys.login.title]: 'Customers',
  [keys.login.form.user]: 'User',
  [keys.login.form.password]: 'Password',
  [keys.newList.title]: 'News',
  [keys.newList.button.seeAll]: 'See all',
  [keys.newList.button.share]: 'Share',
  [keys.contact.title]: 'Contact us',
  [keys.contact.subtitle]: 'We will be pleased to help you',
  [keys.contact.button]: 'Go to Contact',
};
