import { css } from '@emotion/css';
import { theme, getStylesFromObject } from 'core/theme';

export const root = css`
  background-color: ${theme.palette.secondary.main};
  padding: 0.5rem;
`;

export const content = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const owner = css`
  ${getStylesFromObject(theme.typography.body1)}
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: ${theme.palette.common.white};
`;

export const linkContainer = css`
  display: flex;
  flex-direction: row;
  & > li {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  & > :nth-child(n + 2) {
    border-left-color: ${theme.palette.common.white};
    border-left-style: solid;
    border-left-width: 1px;
  }
`;

export const linkText = css`
  ${getStylesFromObject(theme.typography.body1)}
  font-size: 0.7rem;
  line-height: 1.2;
  color: ${theme.palette.common.white};
`;
