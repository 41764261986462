import React from 'react';
import { useScrollTrigger, Zoom, Fab } from '@mui/material';
import KeyboardArrowUpIcon from 'core/assets/icons/keyboard-arrow-up.svg';
import * as classes from './scroll-to-top.styles';

interface Props {
  id: string;
}

export const ScrollToTopComponent: React.FC<Props> = props => {
  const { id } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(`#${id}`);

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div className={classes.root} onClick={handleClick}>
        <Fab className={classes.fab}>
          <KeyboardArrowUpIcon className={classes.icon} />
        </Fab>
      </div>
    </Zoom>
  );
};
