import { namespaces } from './i18n.constants';
import * as coreTranslations from './translations';
import * as appBarTranslations from 'common-app/app-bar/translations';
import * as footerTranslations from 'common-app/footer/translations';
import * as newTranslations from 'common-app/news/translations';
import * as validationsTranslations from 'common-app/validations/translations';
import * as cookesPopupTranslations from 'common-app/cookies-popup/translations';
import * as homeTranslations from 'pods/home/translations';
import * as terminalTranslations from 'pods/terminal/translations';
import * as productAndServicesTranslations from 'pods/product-and-services/translations';
import * as newListTranslations from 'pods/new-list/translations';
import * as privacyTranslations from 'pods/privacy/translations';
import * as legalDisclaimerTranslations from 'pods/legal-disclaimer/translations';
import * as cookiesTranslations from 'pods/cookies/translations';
import * as contactTranslations from 'pods/contact/translations';
import * as authorizationTranslations from 'pods/authorization/translations';

export const en = {
  [namespaces.core]: coreTranslations.en,
  [namespaces.commonApp.appBar]: appBarTranslations.en,
  [namespaces.commonApp.footer]: footerTranslations.en,
  [namespaces.commonApp.new]: newTranslations.en,
  [namespaces.commonApp.validations]: validationsTranslations.en,
  [namespaces.commonApp.cookesPopup]: cookesPopupTranslations.en,
  [namespaces.pods.home]: homeTranslations.en,
  [namespaces.pods.terminal]: terminalTranslations.en,
  [namespaces.pods.productAndServices]: productAndServicesTranslations.en,
  [namespaces.pods.newList]: newListTranslations.en,
  [namespaces.pods.privacy]: privacyTranslations.en,
  [namespaces.pods.legalDisclaimer]: legalDisclaimerTranslations.en,
  [namespaces.pods.cookies]: cookiesTranslations.en,
  [namespaces.pods.contact]: contactTranslations.en,
  [namespaces.pods.authorization]: authorizationTranslations.en,
};

export const es = {
  [namespaces.core]: coreTranslations.es,
  [namespaces.commonApp.appBar]: appBarTranslations.es,
  [namespaces.commonApp.footer]: footerTranslations.es,
  [namespaces.commonApp.new]: newTranslations.es,
  [namespaces.commonApp.validations]: validationsTranslations.es,
  [namespaces.commonApp.cookesPopup]: cookesPopupTranslations.es,
  [namespaces.pods.home]: homeTranslations.es,
  [namespaces.pods.terminal]: terminalTranslations.es,
  [namespaces.pods.productAndServices]: productAndServicesTranslations.es,
  [namespaces.pods.newList]: newListTranslations.es,
  [namespaces.pods.privacy]: privacyTranslations.es,
  [namespaces.pods.legalDisclaimer]: legalDisclaimerTranslations.es,
  [namespaces.pods.cookies]: cookiesTranslations.es,
  [namespaces.pods.contact]: contactTranslations.es,
  [namespaces.pods.authorization]: authorizationTranslations.es,
};
