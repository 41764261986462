import React from 'react';
import { Typography, Container, Menu } from '@mui/material';
import { useMenu } from './menu.hook';
import * as classes from './menu.styles';

interface Props {
  component: 'li';
  title: string;
  children: React.ReactNode;
  className?: string;
}

export const MenuComponent: React.FC<Props> = props => {
  const { component, title, className, children } = props;
  const { isOpen, anchorEl, onOpenMenu, onCloseMenu } = useMenu();

  return (
    <Container component={component} className={className} onMouseOver={onOpenMenu} onMouseLeave={onCloseMenu}>
      <Typography variant="subtitle1" className={classes.title({ isOpen })}>
        {title}
      </Typography>
      <Menu
        classes={{
          paper: classes.paper,
          list: classes.menu,
        }}
        open={isOpen}
        onClose={onCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PopoverClasses={{
          root: classes.popover,
        }}
        PaperProps={{
          onMouseLeave: () => onCloseMenu(),
        }}
      >
        {children}
      </Menu>
    </Container>
  );
};
