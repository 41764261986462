import { css } from '@emotion/css';
import { theme } from 'core/theme';

export const root = css`
  display: flex;
  flex-direction: row;
  & > :last-child {
    border-left-color: ${theme.palette.text.disabled};
    border-left-style: solid;
    border-left-width: 1px;
  }
`;

export const languageButton = css`
  padding: 0;
  border-radius: 0;
  min-width: auto;
`;

interface LanguageProps {
  selected: boolean;
}

export const language = (props: LanguageProps) => css`
  color: ${props.selected ? theme.palette.primary.main : theme.palette.text.disabled};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;
