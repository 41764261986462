import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Typography, MenuItem } from '@mui/material';
import { namespaces } from 'core/i18n';
import { keys } from '../../translations';
import { routes } from 'core/router';
import { MenuComponent } from './menu.component';

interface Props {
  component: 'li';
  className?: string;
}

export const CompanyComponent: React.FC<Props> = props => {
  const { component, className } = props;
  const { t } = useTranslation(namespaces.commonApp.appBar);

  return (
    <MenuComponent className={className} component={component} title={t(keys.company.title)}>
      <MenuItem onClick={() => navigate(routes.company.productAndServices)}>
        <Typography variant="body1" color="inherit">
          {t(keys.company.productAndServices)}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate(routes.company.whoWeAre)}>
        <Typography variant="body1" color="inherit">
          {t(keys.company.whoWeAre)}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate(routes.company.whatDoWeDo)}>
        <Typography variant="body1" color="inherit">
          {t(keys.company.whatDoWeDo)}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate(routes.company.commitments)}>
        <Typography variant="body1" color="inherit">
          {t(keys.company.commitments)}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate(routes.company.authorization)}>
        <Typography variant="body1" color="inherit">
          {t(keys.company.authorization)}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate(routes.company.certifications)}>
        <Typography variant="body1">{t(keys.company.certifications)}</Typography>
      </MenuItem>
    </MenuComponent>
  );
};
