import React from 'react';
import { anchorIds } from 'core/router';
import { AppBarComponent } from 'common-app/app-bar';
import { FooterComponent } from 'common-app/footer';
import { useGlobalValidationMessages } from 'common-app/validations';
import * as classes from './app.layout.styles';

export interface AppLayoutChildrenProps {
  backToTopId: string;
  className: string;
}

interface Props {
  children: (props: AppLayoutChildrenProps) => React.ReactNode;
}

export const AppLayout: React.FC<Props> = props => {
  const { children } = props;
  useGlobalValidationMessages();

  return (
    <>
      <AppBarComponent backToTopId={anchorIds.backToTop} className={classes.content} />
      <main>
        {children({
          backToTopId: anchorIds.backToTop,
          className: classes.content,
        })}
      </main>
      <FooterComponent className={classes.content} />
    </>
  );
};
