import React from 'react';
import { cx } from '@emotion/css';
import { navigate } from 'gatsby';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/router';
import { namespaces } from 'core/i18n';
import { keys } from '../translations';
import * as classes from './nav.styles';

interface Props {
  className?: string;
}

export const NavComponent: React.FC<Props> = props => {
  const { className } = props;
  const { t } = useTranslation(namespaces.commonApp.footer);

  return (
    <nav className={classes.root}>
      <div className={cx(classes.content, className)}>
        <div>
          <Typography className={classes.title}>{t(keys.company.title)}</Typography>
          <ul className={classes.linkContainer}>
            <li>
              <Link onClick={() => navigate(routes.company.productAndServices)}>
                <Typography className={classes.linkText}>{t(keys.company.productAndServices)}</Typography>
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(routes.company.whoWeAre)}>
                <Typography className={classes.linkText}>{t(keys.company.whoWeAre)}</Typography>
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(routes.company.commitments)}>
                <Typography className={classes.linkText}>{t(keys.company.commitments)}</Typography>
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(routes.company.authorization)}>
                <Typography className={classes.linkText}>{t(keys.company.authorization)}</Typography>
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(routes.company.certifications)}>
                <Typography className={classes.linkText}>{t(keys.company.certifications)}</Typography>
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <Typography className={classes.title}>{t(keys.policies.title)}</Typography>
          <ul className={classes.linkContainer}>
            <li>
              <Link onClick={() => navigate(routes.policies.ethicalCode)}>
                <Typography className={classes.linkText}>{t(keys.policies.ethicalCode)}</Typography>
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(routes.policies.csmas)}>
                <Typography className={classes.linkText}>{t(keys.policies.csmas)}</Typography>
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(routes.policies.privacyAndDataProtection)}>
                <Typography className={classes.linkText}>{t(keys.policies.privacyAndDataProtection)}</Typography>
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <Typography className={classes.title}>{t(keys.terminals.title)}</Typography>
          <ul className={classes.linkContainer}>
            <li>
              <Link onClick={() => navigate(routes.terminals.barcelona)}>
                <Typography className={classes.linkText}>{t(keys.terminals.barcelona)}</Typography>
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(routes.terminals.huelva)}>
                <Typography className={classes.linkText}>{t(keys.terminals.huelva)}</Typography>
              </Link>
            </li>
          </ul>

          <div className={classes.news}>
            <Link onClick={() => navigate(routes.news.list)}>
              <Typography className={classes.title}>{t(keys.news)}</Typography>
            </Link>
          </div>
          <div>
            <Link onClick={() => navigate(routes.contact)}>
              <Typography className={classes.title}>{t(keys.contact)}</Typography>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};
