import React from 'react';
import { Providers } from 'core/providers';
import { AppLayout } from 'layouts';

export const wrapRootElement = ({ element, props }) => {
  return <Providers {...props}>{element}</Providers>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <AppLayout>
      {({ backToTopId, className }) =>
        React.cloneElement(element, {
          ...props,
          backToTopId,
          className,
        })
      }
    </AppLayout>
  );
};
