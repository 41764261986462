import { keys } from './keys';

export const en = {
  [keys.featureList.feature.title]: 'Characteristics',
  [keys.featureList.feature.surface]: 'Total area',
  [keys.featureList.feature.capacity]: 'Total capacity',
  [keys.featureList.feature.tanks]: 'Number of tanks',
  [keys.featureList.feature.range]: 'Capacity range',
  [keys.featureList.capacityStatus.available]: 'Available Capacity',
  [keys.featureList.capacityStatus.notAvailable]: 'Not Available Capacity',
  [keys.featureList.productTitle]: 'Products',
};
