import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Typography, MenuItem } from '@mui/material';
import { namespaces } from 'core/i18n';
import { keys } from '../../translations';
import { routes } from 'core/router';
import { MenuComponent } from './menu.component';

interface Props {
  component: 'li';
  className?: string;
}

export const PoliciesComponent: React.FC<Props> = props => {
  const { component, className } = props;
  const { t } = useTranslation(namespaces.commonApp.appBar);

  return (
    <MenuComponent className={className} component={component} title={t(keys.policies.title)}>
      <MenuItem onClick={() => navigate(routes.policies.ethicalCode)}>
        <Typography variant="body1">{t(keys.policies.ethicalCode)}</Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate(routes.policies.csmas)}>
        <Typography variant="body1">{t(keys.policies.csmas)}</Typography>
      </MenuItem>
      <MenuItem onClick={() => navigate(routes.policies.privacyAndDataProtection)}>
        <Typography variant="body1">{t(keys.policies.privacyAndDataProtection)}</Typography>
      </MenuItem>
    </MenuComponent>
  );
};
