import { injectGlobal, css } from '@emotion/css';
import { theme } from 'core/theme';

injectGlobal`
  body {
    background-color: ${theme.palette.common.white};
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }

  ul {
    list-style: none;
    padding-left: 0px;
    margin: 0;
  }

  ol {
    padding-left: 0px;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }
`;

export const content = css`
  max-width: ${theme.breakpoints.values.lg}px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
