import { injectGlobal, css } from '@emotion/css';
import { theme } from 'core/theme';

injectGlobal`
  html {
    scroll-padding-top: 48px;
    scroll-behavior: smooth;
  }
`;

export const root = css`
  background-color: ${theme.palette.common.white};
`;

export const toolbar = css`
  padding-right: 0;

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    padding-left: 0;
  }
`;

export const logo = css`
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const icon = css`
  fill: ${theme.palette.custom.icons.black};
`;
