export const keys = {
  authorizationList: {
    title: 'authorizationList.title',
    headerList: {
      activity: 'authorizationList.headerList.activity',
      certification: 'authorizationList.headerList.certification',
      code: 'authorizationList.headerList.code',
    },
    terminalList: {
      barcelona: 'authorizationList.terminalList.barcelona',
      huelva: 'authorizationList.terminalList.huelva',
      general: 'authorizationList.terminalList.general',
    },
  },
};
