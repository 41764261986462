import React from 'react';

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onOpenMenu = event => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  return {
    isOpen: Boolean(anchorEl),
    anchorEl,
    onOpenMenu,
    onCloseMenu,
  };
};
