import React from 'react';
import { DividerComponent, NavComponent, OwnerComponent } from './components';
import * as classes from './footer.styles';

interface Props {
  className?: string;
}

export const FooterComponent: React.FC<Props> = props => {
  const { className } = props;

  return (
    <footer className={classes.root}>
      <DividerComponent />
      <NavComponent className={className} />
      <OwnerComponent className={className} />
    </footer>
  );
};
