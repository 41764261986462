import { keys } from './keys';

export const en = {
  [keys.form.subject]: 'Petición Contacto Web Pública decal',
  [keys.form.name]: 'Name',
  [keys.form.email]: 'Email',
  [keys.form.company]: 'Company',
  [keys.form.phone]: 'Phone',
  [keys.form.message]: 'Message',
  [keys.form.button]: 'Send',
  [keys.form.acceptPrivacyPolicy]: 'I have read and accept the <0>Privacy Policy</0>',
  [keys.snackbar.success]: 'Thank you for contact with us!',
  [keys.snackbar.error]: 'Please try later, something was wrong :(',
};
