export const keys = {
  productList: {
    product: {
      title: 'productList.product.title',
    },
    service: {
      title: 'productList.service.title',
    },
    operations: {
      title: 'productList.operations.title',
    },
  },
};
