import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { namespaces } from 'core/i18n';
import { routes } from 'core/router';
import { keys } from '../../translations';
import { TerminalListComponent } from './terminal-list.component';
import { CompanyComponent } from './company.component';
import { PoliciesComponent } from './policies.component';
import * as classes from './nav.styles';

export const NavComponent: React.FC = props => {
  const { t } = useTranslation(namespaces.commonApp.appBar);
  return (
    <ul className={classes.root}>
      <TerminalListComponent component="li" />
      <CompanyComponent component="li" />
      <PoliciesComponent component="li" />
      <li>
        <Typography className={classes.title} variant="subtitle1" onClick={() => navigate(routes.news.list)}>
          {t(keys.news)}
        </Typography>
      </li>
      <li>
        <Typography className={classes.title} variant="subtitle1" onClick={() => navigate(routes.contact)}>
          {t(keys.contact)}
        </Typography>
      </li>
    </ul>
  );
};
