import { keys } from './keys';

export const es = {
  [keys.terminalList.title]: 'Terminales',
  [keys.terminalList.surface]: 'Superficie total',
  [keys.terminalList.capacity]: 'Capacidad total',
  [keys.terminalList.tanks]: 'Número de tanques',
  [keys.terminalList.range]: 'Rango capacidades',
  [keys.certificationList.title]: 'Certificaciones',
  [keys.login.title]: 'Clientes',
  [keys.login.form.user]: 'Usuario',
  [keys.login.form.password]: 'Contraseña',
  [keys.newList.title]: 'Noticias',
  [keys.newList.button.seeAll]: 'Ver todas',
  [keys.newList.button.share]: 'Compartir',
  [keys.contact.title]: 'Contáctanos',
  [keys.contact.subtitle]: 'Estaremos encantados de ayudarte',
  [keys.contact.button]: 'Ir a Contacto',
};
