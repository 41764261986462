import React from 'react';
import { cx } from '@emotion/css';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { namespaces, keys, LanguageContext, languages } from 'core/i18n';
import * as classes from './language.styles';

interface Props {
  className?: string;
}

export const LanguageComponent: React.FC<Props> = props => {
  const { className } = props;
  const { language, onChangeLanguage } = React.useContext(LanguageContext);
  const { t } = useTranslation(namespaces.core);

  return (
    <ul className={cx(classes.root, className)}>
      <li>
        <Button className={classes.languageButton} onClick={() => onChangeLanguage(languages.en)}>
          <Typography
            variant="subtitle2"
            className={classes.language({
              selected: language === languages.en,
            })}
          >
            {t(keys.english)}
          </Typography>
        </Button>
      </li>
      <li>
        <Button className={classes.languageButton} onClick={() => onChangeLanguage(languages.es)}>
          <Typography
            variant="subtitle2"
            className={classes.language({
              selected: language === languages.es,
            })}
          >
            {t(keys.spanish)}
          </Typography>
        </Button>
      </li>
    </ul>
  );
};
