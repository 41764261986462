import { css } from '@emotion/css';
import { getStylesFromObject, theme } from 'core/theme';

export const root = css`
  padding: 1rem;
`;

export const content = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    justify-content: center;
    & > :nth-child(n) {
      margin-left: 4rem;
    }
    & > :first-child {
      margin-left: 0;
    }
  }
`;

export const title = css`
  ${getStylesFromObject(theme.typography.h6)}
  font-size: 1rem;
  color: ${theme.palette.common.white};
`;

export const news = css`
  margin-top: 1rem;
`;

export const linkContainer = css`
  & > :nth-child(n) {
    margin-top: 0.25rem;
  }
`;

export const linkText = css`
  ${getStylesFromObject(theme.typography.body1)}
  font-size: 0.7rem;
  line-height: 1.2;
  color: ${theme.palette.common.white};
`;
